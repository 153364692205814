<template>
  <div class="main">
    <div class="top">
      <img :src="CollectionList.activity_img" />
    </div>
    <div class="bottom">
      <div class="title">{{ CollectionList.act_name }}</div>
      <div class="time">
        {{ CollectionList.start_time }} - {{ CollectionList.end_time }}
      </div>
      <div class="btn" @click="codetap(CollectionList.code)">
        {{ CollectionList.timeStatus }}
      </div>
      <!-- 0未开始，1直接显示时间，2已过期 -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    CollectionList: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {
    if (this.CollectionList.timeStatus == 2) {
      this.CollectionList.timeStatus = `${this.$t('haveexpired')}`;
    } else if (this.CollectionList.timeStatus == 0) {
      this.CollectionList.timeStatus = `${this.$t('notstart')}`;
    } else if (this.CollectionList.timeStatus == 1) {
      this.CollectionList.timeStatus = `${this.$t('underway')}`;
    }
  },
  methods: {
    codetap(e) {
      console.log(e, "看看我有code没");
      this.$router.push({
        path: "/activity/songdetail",
        query: {
          code: e,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 388px;
  height: 298px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;

  .top {
    width: 388px;
    height: 205px;
    border-radius: 6px 6px 0px 0px;

    img {
      width: 388px;
      height: 205px;
      border-radius: 6px 6px 0px 0px;
    }
  }

  .bottom {
    width: 388px;
    height: 93px;
    border-radius: 0px 0px 6px 6px;
    position: relative;

    .title {
      width: 260px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @recommendMusicianListTextColor;
      line-height: 22px;
      padding-top: 24px;
      margin-left: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .time {
      width: 240px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 17px;
      margin-top: 6px;
      margin-left: 24px;
    }

    .btn {
      width: 68px;
      height: 28px;
      background: #3370ff;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0px;
      top: 30px;
      color: #ffffff;
      font-size: 12px;
    }
  }
}
</style>