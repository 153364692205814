<template>
  <div class="shell">
    <div class="Carousel">
      <swiper v-if="bannerList.length > 0" class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <a :href="item.url" target="_blank"><img :src="item.image" alt=""></a>
        </swiper-slide>
      </swiper>
      <!-- <img src="../../assets/images/main/banneract.png" /> -->
    </div>
    <div class="Reference">{{$t('songcollection')}}</div>
    <div style="margin-top: 20px;">
      <div class="music">
        <div class="list" v-for="(item, index) in CollectionList" :key="index">
          <Collectionmoudle :CollectionList="item"></Collectionmoudle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIndexBanner, getActivity } from "@/api/Home";
import Collectionmoudle from "@/components/content/Collectionmoudle";
export default {
  props: {},
  data() {
    return {
      CollectionList: [],
      bannerList: []
    }
  },
  components: { Collectionmoudle },
  computed: {},
  created() {
    this.ActivityList(); // ActivityList列表
    this.getBanner();
  },
  mounted() {
  },
  watch: {},
  methods: {
      // 获取banner
      getBanner() {
      getIndexBanner()
        .then((res) => {
          console.log(res, '获取banner成功');
          this.bannerList = res.data.list
        })
        .catch((err) => {
          // console.log(err, '获取banner失败');
        });
    },
    // ActivityList列表
    ActivityList() {
      getActivity({
		  // locale: this.$store.state.countryId == 1 ? 'cn' : this.$store.state.countryId == 2 ? 'ko': ''
      }).then((res) => {
        this.CollectionList = res.data
        console.log(this.CollectionList, "列表成功");
      })
        .catch((err) => {
          console.log(err, "列表失败");
        });
    },
  },
}

</script>
<style lang="less" scoped>
.shell {
  width: 100%;
  height: auto;

  .Carousel {
    width: 100%;
    height: 350px;
    margin-top: 24px;

    img {
      width: 100%;
      height: 350px;
    }

  }

  .Reference {
    width: 117px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 33px;
    margin-top: 20px;
    color: #ffffff;
  }

  .music {
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .list {
      margin-bottom: 20px;
      width: 30%;

    }
	/* 如果最后一行是2个元素 */
	.list:last-child:nth-child(3n - 1) {
		margin-right: calc(30% + 10% / 2);
	}
	
	/* 如果最后一行是1个元素 */
	.list:last-child:nth-child(3n - 2) {
		margin-right: calc(60% + 20% / 2);
	}
  }
}
</style>


