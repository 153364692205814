import http from '@/utils/request/http'

// 获取底部歌手列表
export const getFirstPageFootImage = (params = {}) => {
    return http.get('/api/firstPageFootImage', params)
}
// 获取首页轮播图
export const getIndexBanner = (params = {}) => {
    return http.get('/api/IndexBanner', params)
}
// 首页推荐制作人列表
export const getCreatorList = (params = {}) => {
    return http.get('/api/creatorList', params)
}
// 首页成品音乐列表
export const getTopMusicList = (params = {}) => {
    return http.get('/api/topMusicList', params)
}
// 制作人信息
export const getCreator = (params = {}) => {
    return http.get('/api/creator', params)
}
// 首页demo音乐列表
export const getMusicList = (params = {}) => {
    return http.get('/api/musicList', params)
}
// 制作人音乐列表
export const creatorMusic = (params = {}) => {
    return http.get('/api/creatorMusic', params)
}
// 首页beat音乐列表
export const beatList = (params = {}) => {
    return http.get('/api/beatList', params)
}
// 优惠券列表
export const couponList = (params = {}) => {
    return http.get('/api/couponList', params)
}
//所有标签
export const tagList = (params = {}) => {
    return http.get('/api/tagList', params)
}
//活动专区case列表
export const getCase = (params = {}) => {
    return http.get('/api/getCase', params)
}
//活动列表
export const getActivity = (params = {}) => {
    return http.get('/api/getActivity', params)
}

//活动专区References
export const getReferences = (params = {}) => {
    return http.get('/api/getReferences', params)
}
//音乐库 购物车价格表
export const priceArr = (params = {}) => {
    return http.get('api/priceArr', params)
}
//音乐库BGM
export const bgmList = (params = {}) => {
    return http.get('/api/bgmList', params)
}

// 预约锁定音乐
export const buyLockPrice = (params = {}) => {
    return http.post('/api/buyLockPrice', params)
}
// 预约锁定价格表
export const LockPrice = (params = {}) => {
    return http.get('/api/LockPrice', params)
}

// 获取音乐信息
export const musicInfo = (params = {}) => {
    return http.get('/api/musicInfo', params)
}
// 获取logo
export const logo = (params = {}) => {
    return http.get('/api/logo', params)
}
//领取伴奏
export const getBeat = (params = {}) => {
    return http.get('/api/getBeat', params)
}
// 消息中心
export const getMessage = (params = {}) => {
    return http.get('/api/myMessage', params)
}
//添加播放记录
export const addPlaylog = (params = {}) => {
    return http.get('/api/addPlaylog', params)
}
//删除单条播放记录
export const cancelPlaylog = (params = {}) => {
    return http.get('/api/cancelPlaylog', params)
}
// 播放记录列表
export const myPlaylog = (params = {}) => {
    return http.get('/api/myPlaylog', params)
}
//购买单个音乐
export const buyMusic = (params = {}) => {
    return http.post('/api/buyMusic', params)
}
//推荐页 单独轮播
export const randMusic = (params = {}) => {
    return http.get('/api/randMusic', params)
}